<template>
  <div id="index">
    <div class="video-box" >
      <video
        v-if="showvideo"
        class="video-bg"
        autoplay
        muted
        src="https://llcb.oss-cn-shanghai.aliyuncs.com/fdyqd/dev/202402/bj2024020110020679618.mp4"
        loop
        :style="videoStyle"
      ></video>
    </div>
    <dv-full-screen-container >
      <dv-loading v-if="loading">Loading...</dv-loading>

      <div v-else class="pd-20">
        <div style="width: 100%;height: 140px" class="flex space-between">
          <div class="back" @click="back()">
            <img src="@/assets/back.png" alt="">
          </div>
          <div class="project-name" style="width: 456px;height: 60px" >
            <dv-decoration-3  style="width:250px;height:30px;" />
            <div class="project-title">{{titleInfo.engineeringName}}</div>
          </div>
          <div class="po-re">
            <div class="title po-ab">{{titleInfo.screenTitle}}</div>
            <dv-decoration-5 :color="['#7ce7fd','#7ce7fd', ]" style="width:930px;height:40px;margin-top:30px" :dur="3" />
          </div>
          <div class="address" style="width: 456px;height: 80px">
            <dv-decoration-7 style="width:456px;height:80px;">
              <span>{{titleInfo.areaName}}</span>
              <span>{{titleInfo.statisticDate}}</span>
            </dv-decoration-7>
          </div>
        </div>
        <div class="flex">
          <!-- <div>
             <div style="width: 456px;height: 42vh">
              <dv-border-box-12 class="position-re">
                <div class="left-name">今日在职工人工种统计</div>
                <div id="chartsTop1" class="chart-top">
                </div>
              </dv-border-box-12>
            </div>
            <div style="width: 456px;height: 42vh">
              <dv-border-box-12>
                <div class="left-name">今日出勤工人工种统计</div>
                <div id="chartsTop2" class="chart-top ">
                </div>
              </dv-border-box-12>
            </div>
          </div> -->

          <div>
            <div style="width: 970px;height: 14vh">
              <dv-border-box-12 :reverse="true">
                <div class="center-top flex space-around item-center">
                  <div class="40w">
                    <div class="number green">{{currAttendanceData.totalUserNum}}</div>
                    <div class="name">今日在职人数</div>
                  </div>
                  <div class="40w">
                    <div class="number green">{{currAttendanceData.attendanceUserNum}}</div>
                    <div class="name">今日出勤人数</div>
                  </div>
                  <div class="40w">
                    <div class="number orange">{{currAttendanceData.restUserNum}}</div>
                    <div class="name">今日请假人数</div>
                  </div>
                  <div class="40w">
                    <div class="number orange">{{currAttendanceData.unAttendanceUserNum}}</div>
                    <div class="name">今日未出勤人数</div>
                  </div>
                  <div class="40w">
                    <div class="number orange">{{currAttendanceData.attendanceRate}}%</div>
                    <div class="name">今日出勤率</div>
                  </div>
                </div>
              </dv-border-box-12>
            </div>

            <div style="width: 970px;height: 70vh">
              <dv-border-box-12 :reverse="true">
                <div style="height:35vh">
                  <div class="left-name">最近十四日出勤率趋势图</div>
                  <!-- <div id="chartCenter1" class="chart-center"></div> -->
                </div>
                <div style="height:35vh">
                  <div class="left-name">参建单位今日出勤情况</div>
                  <div class="chart-center" v-if="showtable">
                    <!-- <dv-scroll-board :config="config" style="width:920px;height:29vh" /> -->
                  </div>
                </div>
              </dv-border-box-12>
            </div>
          </div>

          <div>
            <div style="width: 456px;height: 14vh">
              <dv-border-box-12 reverse="true">
                <div style="height:35vh">
                  <div class="left-name">项目处罚记录</div>
                  <div  class="chart-center flex">
                    <div class="log-txt">
                      <span>工人处罚记录</span>
                      <span class="red ml-10">{{todayTotal.workerCount}}</span>
                    </div>
                    <div class="log-txt">
                      <span>项目部人员处罚记录</span>
                      <span class="red ml-10">{{todayTotal.managerCount}}</span>
                    </div>
                  </div>
                </div>
              </dv-border-box-12>
            </div>

            <div style="width: 456px;height: 35vh">
              <dv-border-box-12>
                <div class="left-name">参建单位今日出勤情况</div>
                  <div class="chart-center">
                    <!-- <dv-scroll-board
                      class="dv-scroll-style"
                      v-if="showtable2"
                      :config="config2"
                      style="width:400px;height:29vh" /> -->
                  </div>
              </dv-border-box-12>
            </div>

            <div style="width: 456px;height: 35vh">
              <dv-border-box-12  ref="borderBox">
              <div class="left-name">近三日人员动态记录</div>
                <!-- <div id='chartRight' class="chart-right">
                </div> -->
              </dv-border-box-12>
            </div>

          </div>

        </div>
      </div>

    </dv-full-screen-container>
  </div>
</template>
<script>
let echarts = require('echarts/lib/echarts')

import {
  screenTitleInfo,
  currAttendanceInfo,
  inJobTypeWork,
  attendanceTypeWork,
  attendanceTrend,
  participateCompanyAttendanceList,
  todayPunishTotal,
  todayReformTotal,
  personnelDynamics,
 } from "../../services/construction.js";
export default {
  data() {
    return {
      showvideo:true,
      loading:true,
      videoStyle:"",
      data1:[],
      data2:[],
      dataName1:[],
      dataName2:[],
      objData1:[],
      objData2:[],
      dataCenter:[], //center 值
      dataCenter1:[], //center 值
      dataCenterTime:[], //center 时间
      dataTemplate:[],
      config:{
        header:[
          "参建单位名称",
          "在职人数",
          "请假人数",
          "出勤人数",
          "出勤率",
          "近7日出勤率",
        ],
        data:[],
        columnWidth:[250],
        // headerBGC:"#181d3b",
        headerBGC:"rgba(24,29,59,0.5)",
        // oddRowBGC:"#092b4d",
        oddRowBGC:"rgba(9,43,77,0.5)",
        // evenRowBGC:"#8484184",
        evenRowBGC:"rgba(132,132,132,0.5)",
      },
      config2:{
        header:[
          "开单人",
          "第一责任人",
          "原因",
        ],
        data:[
        ],
        columnWidth:[80,100,],
        columnHeight:[200],
        // headerBGC:"#181d3b",
        headerBGC:"rgba(24,29,59,0.5)",
        // oddRowBGC:"#092b4d",
        oddRowBGC:"rgba(9,43,77,0.5)",
        // evenRowBGC:"#8484184",
        evenRowBGC:"rgba(132,132,132,0.5)",
      },

      rightDataX:[
      ],
      rightDataY1:[],
      rightDataY2:[],
      titleInfo:"",
      todayTotal:"",
      currAttendanceData:"",
      dataTemplate2:"",
      showtable:false,
      showtable2:false,
    }
  },
  created(){
    setTimeout(() => {
      this.cancelLoading()
    }, 100)

    // setInterval(()=>{
    //   this.showvideo = !this.showvideo
    // },5000)
  },
  mounted(){

    this.resizeVideo()
    window.addEventListener("resize", () => {
      this.resizeVideo()
    });
    setTimeout(()=>{
      //  标题接口
      this.screenTitleInfoFn()
      inJobTypeWork({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        console.log(res)
        if(res.code === 200){
          this.dataName1 = []
          res.data.forEach(item=>{
            item.value = item.count
            item.name = item.varietiesName
            this.dataName1.push(item.name)
          })
          this.data1 = res.data
          this.initChartsTop1()
        }
      })

      attendanceTypeWork({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        console.log(res)
        if(res.code === 200){
          this.dataName2 = []
          res.data.forEach(item=>{
            item.value = item.count
            item.name = item.varietiesName
              this.dataName2.push(item.name)
          })
          this.data2 = res.data
          console.log(this.dataName2,this.data2)
          this.initChartsTop2()
        }
      })

      attendanceTrend({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        if(res.code == 200){
          this.dataCenterTime = res.data.statisticKeyList
          this.dataCenter = res.data.statisticValueList
          this.dataCenter1 = res.data.statisticValueList
          this.initChartsCenter1()
        }
      })
      personnelDynamics({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        if(res.code == 200){
          this.rightDataX = []
          this.rightDataY1 = []
          this.rightDataY2 = []
          res.data.forEach(item=>{
            this.rightDataX.push(item.statisticDate)
            this.rightDataY1.push(item.entryUserNum)
            this.rightDataY2.push(item.leaveUserNum)
          })
          this.initChartsRight()
        }
      })
    },500)
  },
  methods:{
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    screenTitleInfoFn(){
      screenTitleInfo({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        console.log(res)
        this.titleInfo = res.data
      })
      todayPunishTotal({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        console.log(res)
        this.todayTotal = res.data
      })
      currAttendanceInfo({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        console.log(res)
        this.currAttendanceData = res.data
      })
      participateCompanyAttendanceList({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        console.log(res)
        this.dataTemplate = []
        res.data.forEach(item=>{
          let arr = []
          arr[0] = item.companyName
          arr[1] = item.totalUserNum
          arr[2] = item.restUserNum
          arr[3] = item.attendanceUserNum
          arr[4] = item.attendanceRate+"%"
          arr[5] = item.historyAttendanceRate+"%"
          this.dataTemplate.push(arr)
        })
        // this.config.data = this.dataTemplate
        this.config.data = this.config.data.concat(this.dataTemplate)
        this.showtable = true
      })

      todayReformTotal({
        businessId:sessionStorage.getItem('engineeringSecondId'),
      }).then(res=>{
        console.log(res)
        this.dataTemplate2 = []
        res.data.forEach(item=>{
          let arr = []
          arr[0] = item.createName
          arr[1] = item.receiverName
          arr[2] = item.content
          this.dataTemplate2.push(arr)
        })
        this.config.data = this.dataTemplate
        this.config2.data = this.config2.data.concat(this.dataTemplate2)
        this.showtable2 = true
      })

    },


    resizeVideo() {
      let screenScale = window.innerWidth / window.innerHeight;
      this.videoStyle = {
        width: 100 + (10/screenScale)  + "%",
        left: -(10/screenScale) + "%",
      }
    },
    array2obj1(array, key) {
      var resObj = {};
      for (var i = 0; i < array.length; i++) {
        resObj[array[i][key]] = array[i];
      }
      return resObj;
    },
    initChartsTop1(){
      let _this = this
      _this.objData1 = _this.array2obj1(_this.data1, "name");
      //  接口请求
        let myChart = echarts.init(document.getElementById('chartsTop1'));
        let option = {
            tooltip: {
              trigger: "item",
            },
            graphic: {
              elements: [
                {
                  type: "image",
                  style: {
                    width: 100,
                    height: 100,
                  },
                  left: "center",
                  top: "center",
                },
              ],
            },
            legend: {
              orient: "horizontal",
              icon: "circle",
              bottom: 0,
              x: "center",
              formatter: function (name) {
                return name +  _this.objData1[name].value ;
              },
              textStyle: {
                color: "#fff",
              },
              data: this.dataName1,
            },
            series: [
              {
                type: "pie",
                radius: ["40%", "50%"],
                center: ["50%", "50%"],
                // color: ["#0E7CE2", "#FF8352", "#E271DE", "#F8456B", "#00FFFF", "#4AEAB0"],
                data: this.data1,
                labelLine: {
                  normal: {
                    show: true,
                    length: 0,
                    length2: 0,
                    lineStyle: {
                      color: "#12EABE",
                      width: 2,
                    },
                  },
                },
                label: {
                  normal: {
                    formatter: "",
                    // formatter: "{c|{c}}\n{hr|}\n{d|{d}%}",
                    rich: {
                      b: {
                        fontSize: 20,
                        color: "#12EABE",
                        align: "left",
                        padding: 4,
                      },
                      hr: {
                        borderColor: "#12EABE",
                        width: "100%",
                        borderWidth: 2,
                        height: 0,
                      },
                      d: {
                        fontSize: 20,
                        color: "#fff",
                        align: "left",
                        padding: 4,
                      },
                      c: {
                        fontSize: 20,
                        color: "#fff",
                        align: "left",
                        padding: 4,
                      },
                    },
                  },
                },
              },
            ],
          };
        myChart.setOption(option);
    },
    array2obj2(array, key) {
      var resObj = {};
      for (var i = 0; i < array.length; i++) {
        resObj[array[i][key]] = array[i];
      }
      console.log(resObj,"22222")
      return resObj;
    },
    initChartsTop2(){
      let _this = this
      _this.objData2 = _this.array2obj1(_this.data2, "name");
      //  接口请求
        let myChart = echarts.init(document.getElementById('chartsTop2'));
        let option = {
            tooltip: {
              trigger: "item",
            },
            graphic: {
              elements: [
                {
                  type: "image",
                  style: {
                    width: 100,
                    height: 100,
                  },
                  left: "center",
                  top: "center",
                },
              ],
            },
            legend: {
              orient: "horizontal",
              icon: "circle",
              bottom:50,
              x: "center",
              formatter: function (name) {
                return name + _this.objData2[name].value ;
              },
              textStyle: {
                color: "#fff",
              },
              data: this.dataName2,
            },
            series: [
              {
                type: "pie",
                radius: ["40%", "50%"],
                center: ["50%", "50%"],
                // color: ["#0E7CE2", "#FF8352", "#E271DE", "#F8456B", "#00FFFF", "#4AEAB0"],
                data: this.data2,
                labelLine: {
                  normal: {
                    show: true,
                    length: 0,
                    length2: 0,
                    lineStyle: {
                      color: "#12EABE",
                      width: 2,
                    },
                  },
                },
                label: {
                  normal: {
                    formatter: "",
                    // formatter: "{c|{c}}\n{hr|}\n{d|{d}%}",
                    rich: {
                      b: {
                        fontSize: 20,
                        color: "#12EABE",
                        align: "left",
                        padding: 4,
                      },
                      hr: {
                        borderColor: "#12EABE",
                        width: "100%",
                        borderWidth: 2,
                        height: 0,
                      },
                      d: {
                        fontSize: 20,
                        color: "#fff",
                        align: "left",
                        padding: 4,
                      },
                      c: {
                        fontSize: 20,
                        color: "#fff",
                        align: "left",
                        padding: 4,
                      },
                    },
                  },
                },
              },
            ],
          };
        myChart.setOption(option);
    },

    initChartsCenter1(){
      // let _this = this
      let myChart = echarts.init(document.getElementById('chartCenter1'));
      let option = {
          grid: {
          left: 10,
          top: "10%",
          bottom: 20,
          right: 40,
          containLabel: true,
        },
        tooltip: {
          show: true,
          backgroundColor: "#7ce7fd",
          borderColor: "#7ce7fd",
          borderWidth: 1,
          formatter: "{b}:（{c}%）",
          extraCssText: "box-shadow: 0 0 5px rgba(0, 0, 0, 1)",
        },
        legend: {
          right: 0,
          top: 0,
          data: ["距离"],
          textStyle: {
            color: "#5c6076",
          },
        },
        xAxis: {
          data: this.dataCenterTime,
          boundaryGap: false,
          axisLine: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#7ce7fd",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          ayisLine: {
            show: false,
          },
          axisLabel: {
            formatter: "{value}%",
            textStyle: {
              color: "#7ce7fd",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#2e3547",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#384157",
            },
          },
        },

        series: [
          {
            type: "bar",
            tooltip: {
              show: false,
            },
            animation: false,
            barWidth: 1.4,
            hoverAnimation: false,
            data: this.dataCenter,
            itemStyle: {
              normal: {
                color: "#7ce7fd",
                opacity: 0.6,
                label: {
                  show: false,
                },
              },
            },
          },
          {
            type: "line",
            name: "linedemo",
            smooth: true,
            symbolSize: 10,
            animation: false,
            lineWidth: 1.2,
            hoverAnimation: false,
            data: this.dataCenter,
            symbol: "circle",
            itemStyle: {
              normal: {
                color: "#7ce7fd",
                shadowBlur: 40,
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#ffffff",
                    fontSize: 16
                  },
                },
              },
            },
            areaStyle: {
              normal: {
                color: "#7ce7fd",
                opacity: 0.08,
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initChartsRight(){
      let myChart = echarts.init(document.getElementById('chartRight'));
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          bottom: "10%",
          data: ["入职", "离职"],
        },
        toolbox: {
          show: true,
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            data: this.rightDataX,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "入职",
            type: "bar",
            data: this.rightDataY1,
            label: {
              normal: {
                show: true,
                position: "top",
                formatter: function (params) {
                  return params.data.value;
                },
                textStyle: {
                  color: "#fff",
                },
              },
            },

          },
          {
            name: "离职",
            type: "bar",
            label: {
              normal: {
                show: true,
                position: "top",
                formatter: function (params) {
                  return params.data.value;
                },
                textStyle: {
                  color: "#fff",
                },
              },
            },
            data: this.rightDataY2,
          },
        ],

      }
      myChart.setOption(option);
    },
    back(){
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped lang="less">
  .back{
    width: 50px;
    height: 50px;
    img{
      width: 50px;
      height: 50px;
    }
  }
  #index{
    // width: 100%;
    // height: 100%;
    // background: red !important;

  }
  .video-bg{
    position: fixed;
    top: 0;
    // left: -5%;
    width: 110%;
    z-index: 1;
  }
  .video-bgs{
    position: fixed;
    top: 0;
    // // left: -5%;
    // width: 110%;
    // z-index: 0;
  }
  .center-top{
    height: 14vh;
  }
  .flex{
    display: flex;

  }
  .space-between{
    justify-content: space-between;
  }
  .space-around{
    justify-content: space-around;
    align-items: center;
  }
  .item-center{
    align-items: center;
  }

  .title{
    font-size: 0.46rem;
    color: #ffffff;
    font-weight: 700;
    width: 100%;
    text-align: center;
    line-height:1.3rem;
  }
  .project-name{
    font-size: 0.22rem;
    color: #ffffff;
    font-weight: 500;
    line-height:1.5rem;
    text-align: left;
    margin-left: 0.25rem;
  }
  .address{
    font-size: 0.22rem;
    color: #ffffff;
    font-weight: 500;
    text-align: right;
    margin-top: 0.5rem;
  }
  .po-re{
    position: relative;
  }
  .po-ab{
    position: absolute;
  }
  .pd-20{
    padding: 0 0.2rem;
  }
  .whleft1{
    width: 4.56rem;
    height: 3.80rem;
  }
  .number{
    font-size: 0.24rem;
    color: #fff;
    font-weight: 600;
  }
  .name{
    font-size: 0.18rem;
    color: #fff;
    margin-top: 0.10rem;
  }
  .w40{
    width: 25%;
  }
  .green{
    color: green;
  }
  .orange{
    color: orange;
  }
.left-name{
  font-size: 0.18rem;
  color: #fff;
  text-align: left;
  padding: 0.15rem ;
}
.chart-top{
  width: 400px;
  height: 38vh;
  margin-top: -0.6rem;
  margin-left: 0.2rem;
}
.chart-center{
  width: 950px;
  height: 32vh;
  margin-top: -0.1rem;
  padding: 0 0.2rem;

}
.log-txt{
  font-size: 0.18rem;
  width: 220px;
  text-align: left;
  color: #fff;
  margin-top: 0.2rem;
}
.red{
  color: red;
}
.ml-10{
  margin-left: 0.1rem;
}
.chart-right{
  width:456px;
  height: 31vh;
}
.position-re{
  position: relative;
}
.bgred{
  position: absolute;
  bottom: 0.1rem;
  left: 50%;
  margin-left: -210px;
}
.wrap{
  flex-wrap: wrap;
}
.legend{
  width: 200px;
  height: 30px;
  background: red;
  display: flex;

  font-size: 0.18rem;
  .icon-color{
    width: 15px;
    height: 15px;
    background: yellow;
  }
}
.video-box{
  position: relative;
}
.mb{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(31,32,32,0.5);

}


// :nth-child(3){
//   color: red;

// }
</style>
<style >
.dv-scroll-style .rows .ceil{
  white-space:wrap;
  overflow:hidden;
  line-height: 25px;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
}
.project-title{
  height: 100px;
  display:-webkit-box;
	overflow:hidden;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:1;
}

</style>
